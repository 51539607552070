import React from 'react'
import { handleAuthentication } from '../utils/auth'

const Callback = () => {
  handleAuthentication()

  return (
    <div>
      <p>Redirecting...</p>
    </div>
  )
}

export default Callback
